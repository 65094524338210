@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #1e1e1e;
  font-family: 'DM Sans', sans-serif;
  font-size: 16px;
  color: white;
}

/* header */
.signup-button {
  background-image: linear-gradient(99.66deg, #001F46 0%, #00C9D1 139.45%);
}

.dropdown-menu {
  position: absolute;
  top: 60px;
  right: 10px;
}

.dropdown-menu a {
  display: block;
  padding: 6px;
  text-align: center;
}

/* footer */
.footer {
  background-image: url("../public/images/footer_bg.png");
  background-position: 70% top;
  background-size: auto 100%;
}

@media screen and (min-width: 1366px) {
  .footer {
    background-size: 100% 100%;
  }
}

/* landing */
.carousel .control-dots .dot {
  width: 12px; /* Set the width of the rectangle */
  height: 3px; /* Set the height of the rectangle */
  border-radius: 3px; /* Optional: to slightly round the corners */
  background-color: #ccc; /* Default color */
  margin: 0 5px; /* Space between dots */
}

.carousel .control-dots .dot.selected {
  width: 40px;
  background-color: #00FFF0;
}

.carousel .control-dots .dot:hover {
  height: 4px;
}

.landing-courses {
  background-image: url('../public/images/landing_courses_bg.png');
  background-size: cover;
}

.landing-dobretech-feature:hover {
  box-shadow: 0 0 20px #DDD;
  cursor: pointer;
}

.landing-readmore-button {
  box-shadow: 0px 8px 40px rgba(0, 122, 132, 0.15);
}

.landing-activities {
  box-shadow: 0px 8px 40px rgba(0, 122, 132, 0.15);
}

.review-carousel .carousel img {
  width: auto !important;
}

.review-carousel-card {
  box-shadow: 0px 15px 60px rgba(11, 112, 119, 0.1);
}

.review-carousel .carousel .control-dots .dot.selected {
  background-color: black;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  position: absolute; /* Allows positioning */
  background: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

/* apply now page */
.apply-form-input {
  box-shadow: 0px 17px 30px -10px rgba(0, 122, 132, 0.15);
}

.apply-form-input, .react-date-picker {
  display: block;
}

.apply-form-input .react-date-picker__wrapper {
  border: none;
}

/* about us page */
.dobre-signify {
  background-image: url(../public/images/about_us_gradient_bg.png);
  background-size: 100% 100%;
}

/* services page */
.services-info-card {
  box-shadow: 0px 37px 80px -20px rgba(0, 122, 132, 0.1);
}

/* news detail */

.news-detail-card, .news-other-card {
  box-shadow: 0px 37px 124.5px -20px rgba(0, 122, 132, 0.15);
  border-radius: 24px;
}

.news-detail-card h2 {
  font-size: 36px;
  padding: 20px;
  font-weight: 500;
}

.news-detail-card p img {
  width: 100%;
}

.news-detail-card .wp-block-kevinbatdorf-code-block-pro {
  background-color: rgba(0, 0, 0, 0.01);
  border: 1px solid rgba(51, 51, 51, 0.1);
  margin: 5px 0;
}

.news-detail-card .wp-block-kevinbatdorf-code-block-pro .line {
  display: block;
  unicode-bidi: embed;
  font-family: monospace;
  white-space: pre;
}

.news-detail-card table {
  margin: 10px;
  caption-side: bottom;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
  border: 1px solid rgba(51, 51, 51, 0.1);
}

.news-detail-card th, .news-detail-card td {
  padding: 0.4em;
  border-width: 0 1px 1px 0;
}

.news-detail-card a {
  color: #FD661F;
}

/* Modal.css */
.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 20px;
  width: 300px; /* Adjust width as necessary */
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: transparent;
  font-size: 30px;
  cursor: pointer;
}

.loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}